// React
import React from "react";

// Components
import Seo from "../components/Seo";
import {PublicNavbar} from "../components/Navbars";
import Footer from "../components/Footer";
import Link from "../components/Link";

// Material ui
import {
  Container,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ArrowForward} from "@material-ui/icons";

// Constants
import * as routes from "../constants/routes";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
  },
  content: {
    background: "linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(255,255,255,1) 50%)",
  },
  title: {
    marginTop: theme.spacing(8),
    fontWeight: 700,
  },
  faqTitle: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3),
    fontWeight: 700,
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  giftBoxImg: {
    margin: "auto",
    width: 400,
    height: 300,
    // background: "white",
    // borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  faqContainer: {
    maxWidth: 1000,
    margin: "auto",
    paddingBottom: theme.spacing(6),
  },
  faqQuestionContainer: {
    padding: theme.spacing(2),
  },
  faqQuestion: {
    fontWeight: 700,
    paddingBottom: theme.spacing(1),
  }
}));

const faqQuestions = [
  {
    id: "futurePrice",
    question: "What pricing will be introduced in the future?",
    answer: "That has not yet been decided but we do make one promise; A free tier will forever continue to exist. We will most likely add a seat-based subscription that grants access to premium features. We will inform users timely about any pricing changes."
  },
  {
    id: "whoRunsCompany",
    question: "Does Maxer replace business messaging apps (e.g. Slack, Teams, Workplace...)?",
    answer: "No, Maxer is a performance marketing workspace assisting teams in goal-setting, reporting and note-taking. Although some overlapping communication features exist, Maxer is not a messaging app. We actually have plans to complement messaging apps through performance notifications."
  },
  {
    id: "isMaxerSafe",
    question: "Is Maxer secure to use?",
    answer: "Most definitely. Preserving the integrity of your data is always our top priority."
  },
  {
    id: "changes",
    question: "Will Maxer make changes in my connected accounts?",
    answer: "No, Maxer only reads from your connected accounts. "
  },
];


export default function PricingPage() {
  const classes = useStyles();
  const firstOfJulyDate = new Date(2021, 6, 1);
  const description = `We are currently in public Beta. All teams get free access to all features in our performance marketing workspace, until at least ${firstOfJulyDate.toLocaleDateString()}.`;
  return (
    <Box>
      <Seo
        title="Pricing - Maxer"
        description={description}
        pathname="/privacy-policy"
        article={null}
      />
      <PublicNavbar/>
      <Box className={classes.content}>
        <Container>
          <Typography
            variant="h5"
            component="h1"
            align="center"
            className={classes.title}
          >
            {`Try out Maxer for free until ${firstOfJulyDate.toLocaleDateString()}`}
          </Typography>
          <Typography
            align="center"
            variant="h6"
            component="p"
            className={classes.subtitle}
          >
            We are currently in public beta and cannot wait to hear from you!
          </Typography>
          <Typography
            variant="h6"
            component="p"
            align="center"
            style={{display: "flex"}}
          >
            <Link to={routes.signup} color="primary" margin="auto">
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
                <span style={{fontWeight: 700}}>Use Maxer for free</span>
                <ArrowForward style={{fontSize: "1.2rem", marginLeft: 3}}/>
              </div>
            </Link>
          </Typography>
          <div style={{textAlign: "center"}}>
            <video width='360' height='240' loop autoPlay muted>
              <source
                src="https://videos.ctfassets.net/h6w739vsk2p5/3mvPwIp5kws4QR7BhBdk8z/918c05f2970c7966762472e9fed4707b/gifts-video.mp4"
                type='video/mp4'
              />
            </video>
          </div>
          <Grid
            container
            spacing={4}
            alignContent="center"
            justify="center"
            className={classes.faqContainer}
          >
            <Grid xs={12}>
              <Typography
                variant="h6"
                component="h1"
                align="center"
                className={classes.faqTitle}
              >
                Frequently asked questions
              </Typography>
            </Grid>
            {faqQuestions
              .map(({id, question, answer}) =>
                <Grid xs={12} md={6} className={classes.faqQuestionContainer} key={id}>
                  <Typography className={classes.faqQuestion}>{question}</Typography>
                  <Typography>{answer}</Typography>
                </Grid>
              )
            }
          </Grid>
        </Container>
      </Box>
      <Footer/>
    </Box>
  );
}

